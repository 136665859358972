var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tinymce-editor" },
    [
      !_vm.value
        ? _c(
            "div",
            {
              staticClass: "tinymce-placeholder",
              style: { zIndex: _vm.zIndex },
              on: { click: _vm.placeholderClick },
            },
            [_vm._v(_vm._s(_vm.placeholder))]
          )
        : _vm._e(),
      !_vm.reloading
        ? _c("editor", {
            ref: "editor",
            attrs: { init: _vm.init, disabled: _vm.disabled },
            on: { onBlur: _vm.onBlur, onClick: _vm.onClick },
            model: {
              value: _vm.myValue,
              callback: function ($$v) {
                _vm.myValue = $$v
              },
              expression: "myValue",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }